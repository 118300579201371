export default {
    buildMode: "slot",
    diaryApiBaseUrl: "https://diaryapi.learnbody.co.kr/",
    diaryReportApiBaseUrl: "https://dairyreportapi.learnbody.co.kr/",
    guideApiBaseUrl: "https://guideapi.learnbody.co.kr/",
    marketApiBaseUrl: "https://marketapi.learnbody.co.kr/",
    marketStorageUrl: "https://learnbodykr.blob.core.windows.net/diary-images/",
    cdnUrl: "https://learnbodykr.blob.core.windows.net/cdn/",
    storageDIR: "diary-user-photo",
    shopByBaseUrl: "https://shop-api.e-ncp.com/",
    shopByClientId: "xRzkr7sOuawIgcDtOgSWSA==",
    communityDomain: "https://communityapp.learnbody.co.kr/",
    marketDomain: "https://market.learnbody.co.kr",
    guideDomain: "https://guide.learnbody.co.kr",
    fitnessApiBaseUrl: "https://fitnessapi.learnbody.co.kr/",
    fitnessSignalRUrl: "https://fitnesssignalhub.learnbody.co.kr/",
    fitnessProfileStorageUrl: "user-fitness-profile",
    scheduleApiBaseUrl: "https://schedule.learnbody.co.kr/",
};
